import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import OutOfDate from 'src/images/browser_out_of_date.png'
import { i18nTranslate } from 'src/utils'
import { whyUpgradeList, browsersList } from './unSupportedJson'
import { getLocalStorage, setLocalStorage } from 'config/appConfig'
import './styles.scss'

const localeFileName = 'ssr-resource'

const UnSupported = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (
      !window?.ReactNativeWebView ||
      window?.navigator?.product != 'ReactNative'
    ) {
      if (!getLocalStorage('unsupportedBrowser')) {
        let browser = getBrowser()
        if (!isSupported(browser)) {
          setShow(true)
        }
      }
    }
  }, [])

  const handleClose = () => {
    setShow(false)
    setLocalStorage('unsupportedBrowser', true)
  }

  const getBrowser = () => {
    const browserConfig = {
      name: '',
      version: '',
    }

    try {
      let agent = navigator?.userAgent,
        temp,
        matched =
          agent?.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || []
      if (/trident/i.test(matched[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(agent) || []
        return { name: 'IE', version: temp[1] || '' }
      }
      if (matched[1] === 'Chrome') {
        temp = agent?.match(/\bOPR\/(\d+)/)
        if (temp != null) {
          return { name: 'Opera', version: temp[1] }
        }
      }
      if (window?.navigator?.userAgent?.indexOf('Edge') > -1) {
        temp = agent?.match(/\Edge\/(\d+)/)
        if (temp != null) {
          return { name: 'Edge', version: temp[1] }
        }
      }
      if (window?.navigator?.userAgent?.indexOf('Edg') > -1) {
        temp = agent?.match(/\Edg\/(\d+)/)
        if (temp != null) {
          return { name: 'Edge', version: temp[1] }
        }
      }
      matched = matched[2]
        ? [matched[1], matched[2]]
        : [navigator?.appName, navigator?.appVersion, '-?']
      if ((temp = agent?.match(/version\/(\d+)/i)) != null) {
        matched?.splice(1, 1, temp[1])
      }

      const isIos = /(iPhone|iPod|iPad).*AppleWebKit/i.test(agent)
      if (isIos) {
        matched[0] = 'iOS'
        matched[1] = agent
          ?.match(/OS [\d_]+/i)[0]
          ?.substr(3)
          ?.split('_')
          ?.map(n => parseInt(n))?.[0]
      }

      const isAndroid = /(Android).*AppleWebKit/i.test(agent)
      if (isAndroid) {
        matched[0] = 'Android'
        matched[1] = agent.match(/android\s([0-9\.]*)/i)?.[1]
      }

      browserConfig.name = matched[0]
      browserConfig.version = matched[1]
    } catch (e) {
      console.warn('Fn getBrowser exception:', e)
    }
    return browserConfig
  }

  const isSupported = (browser = {}) => {
    let supported = false
    if (
      (browser?.name === 'Chrome' && browser?.version >= 48) ||
      browser?.name === 'Firefox' ||
      browser?.name === 'Safari' ||
      browser?.name === 'Edge' ||
      browser?.name === 'iOS' ||
      browser?.name === 'Android' ||
      window?.isNativeApp == true ||
      window?.isVeraApp == true
    ) {
      supported = true
    } else {
      console.error('unsupported browser ', browser)
    }
    return supported
  }

  const renderUnSupportedHeader = () => {
    return (
      <>
        <div className="modal-image-container">
          <img alt="out-of-date" src={OutOfDate} />
        </div>
        <div className="modal-text-container">
          <h1>
            {i18nTranslate(
              'unsupported.heading',
              'OUR SITE IS NOT OPTIMIZED FOR YOUR BROWSER',
              {
                nameSpace: localeFileName,
              }
            )}
          </h1>
          <p>
            {i18nTranslate(
              'unsupported.subHeading',
              'In order to ensure you have the best experience we recommend you upgrade to one of the options below.',
              {
                nameSpace: localeFileName,
              }
            )}
          </p>
        </div>
      </>
    )
  }

  const renderUnSupportWhyUpgrade = () => {
    const listData = whyUpgradeList()
    return (
      <>
        <div className="why-upgrade">
          <h1 className="mt-3">
            {i18nTranslate('unsupported.whyUpgrade', 'WHY UPGRADE ?', {
              nameSpace: localeFileName,
            })}
          </h1>
          <ul>
            {listData?.map(item => (
              <div className="bullet" key={item?.label}>
                <li className="bullet-point">{item?.label}</li>
                <p>{item?.value}</p>
              </div>
            ))}
          </ul>
          <div className="nothanks-button">
            <button onClick={handleClose}>
              {i18nTranslate('unsupported.NoThanks', 'NO THANKS', {
                nameSpace: localeFileName,
              })}
            </button>
          </div>
        </div>
      </>
    )
  }

  const renderUnSupportUpgradeOptions = () => {
    return (
      <div className="upgrade-options d-flex align-items-center">
        <h1 className="mt-3">
          {i18nTranslate('unsupported.UpgradeOptions', 'UPGRADE OPTIONS', {
            nameSpace: localeFileName,
          })}
        </h1>
        <p className="mb-3">
          {i18nTranslate(
            'unsupported.SelectAnOption',
            'Select an option below to get started!',
            {
              nameSpace: localeFileName,
            }
          )}
        </p>

        {browsersList?.map(browser => (
          <div className="browser-list d-flex" key={browser?.name}>
            <div className="browser-icons  ">
              <img src={browser?.imgUrl} alt="chrome" />
            </div>
            <div className="browser-details ml-2">
              <a href={browser?.link} target="_blank" rel="noreferrer">
                <h2>{browser?.name}</h2>
                <p>
                  {i18nTranslate(
                    'unsupported.DownloadAndInstall',
                    'Download and install now!',
                    {
                      nameSpace: localeFileName,
                    }
                  )}
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Modal show={show} onHide={handleClose} className="unsupported-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {renderUnSupportedHeader()}
        <div className="modal-bottom-container d-flex justify-content-center">
          {renderUnSupportWhyUpgrade()}
          {renderUnSupportUpgradeOptions()}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UnSupported

export { UnSupported }
