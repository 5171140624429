import ChromeImg from 'src/images/chrome.png'
import FirefoxImg from 'src/images/firefox.png'
import SafariImg from 'src/images/safari.png'
import { i18nTranslate } from 'src/utils'

export const whyUpgradeList = () => {
  const fileName = 'ssr-resource'
  return [
    {
      label: i18nTranslate(
        'unsupported.loadFasterLabel',
        'Websites load faster',
        {
          nameSpace: fileName,
        }
      ),
      value: i18nTranslate(
        'unsupported.loadFasterValue',
        'Newer browsers often offer double the browsing speed',
        {
          nameSpace: fileName,
        }
      ),
    },
    {
      label: i18nTranslate('unsupported.secureLabel', 'More secure browsing', {
        nameSpace: fileName,
      }),
      value: i18nTranslate(
        'unsupported.secureValue',
        'More security and phishing protection',
        {
          nameSpace: fileName,
        }
      ),
    },
    {
      label: i18nTranslate(
        'unsupported.betterFeatureLabel',
        'Better features',
        {
          nameSpace: fileName,
        }
      ),
      value: i18nTranslate(
        'unsupported.betterFeatureValue',
        'Tabs and better printing options',
        {
          nameSpace: fileName,
        }
      ),
    },
    {
      label: i18nTranslate(
        'unsupported.displayBetterLabel',
        'Sites display better',
        {
          nameSpace: fileName,
        }
      ),
      value: i18nTranslate(
        'unsupported.displayBetterValue',
        'Modern browsers display our pages better',
        {
          nameSpace: fileName,
        }
      ),
    },
  ]
}
export const browsersList = [
  {
    name: 'Google Chrome',
    imgUrl: ChromeImg,
    link: 'https://www.google.com/intl/en/chrome/',
  },
  {
    name: 'Mozilla Firefox',
    imgUrl: FirefoxImg,
    link: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  {
    name: 'Apple Safari',
    imgUrl: SafariImg,
    link: 'https://www.apple.com/safari/',
  },
]
